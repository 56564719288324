<section style="background: linear-gradient(#000000bf, rgb(3 3 3 / 66%));" >
  <app-header></app-header>
  <div class="container">
    <h2 class="section-title lasthead text-center py-5">
      <b class="base--color"> Meet the </b>
      <span class="font-weight-normal text-white">cross-chain </span>
      <b class="base--color">auto yield farming</b>
    </h2>

    <div class="row margingap ">
      <div class="col-md-3">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Total DAI Invested</h5>
          </div>
          <div class="card-body">
            <h6 class="amount" >{{contractInfo?._invested/1e18 | number:'0.0-4'}}  <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" /></h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Total DAI Withdrawn</h5>
          </div>
          <div class="card-body">
            <h6 class="amount">{{contractInfo?._withdrawn/1e18 | number:'0.0-4'}}  <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" /></h6>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Total User</h5>
          </div>
          <div class="card-body">
            <h6 class="amount">{{contractInfo?._totalUsers}}</h6>
          </div>
        </div>
      </div>

      <div class="col-md-3 ">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Total Business</h5>
          </div>
          <div class="card-body">
            <h6 class="amount">{{playerdetails?.totalReferralInvested/1e18 | number:'0.0-4'}}</h6>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-3">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Matrix Income</h5>
          </div>
          <div class="card-body">
            <h6 class="amount">{{playerdetails?.matrixIncome/1e18 | number:'0.0-4'}}</h6>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-3">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Royalty Fund</h5>
          </div>
          <div class="card-body">
            <h6 class="amount">{{royaltyFunds/1e18 | number:'0.0-4'}}</h6>
          </div>
        </div>
      </div>

      <div class="col-md-3 mt-3">
        <div class="card">
          <div class="card-header usedhead">
            <h5>Pension Fund</h5>
          </div>
          <div class="card-body">
            <h6 class="amount">{{pensionFunds/1e18 | number:'0.0-4'}}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row margingap">
      <div class="col-md-8">
        <label  class="form-label">* Min 10 DAI</label>
        <input
        type="number"
            #amount
          class="form-control deposite-form"
          aria-describedby="passwordHelpBlock"
          placeholder="Enter Amount"
        />
        
      </div>
      <div class="col-md-4">
        <button type="submit" (click)="deposit(amount.value)" class="btn btn-primary btn-block depositebutton"> <i class="fas fa-exchange-alt"></i>  DEPOSITE</button>
      </div>
     
    </div>
    <div class="row margingap">
        <div class="col-md-4">
            <div class="bused" >
                <p class="m-0">Available DAI <img src="assets/img/icon.webp" alt="" width="20px" class="moneyicon" /> Balance : </p>
                <p class="m-0" >{{userbalance/1e18 | number:'0.0-4'}}</p>
            </div>
        </div>
        <div class="col-md-4">
<div class="bused1">
    <p class="m-0" >Referral Bonus : </p>
    <p class="m-0" >{{playerdetails?.totalBonus/1e18 | number:'0.0-4'}}</p>
</div>
        </div>
        <div class="col-md-4">
            
            <div class="bused" >
                <p class="m-0">Total Withdrawn : </p>
                {{playerdetails?.totalWithdrawn/1e18}}</div>
        </div>
    </div>
    <div class="row margingap">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header border1 d-flex ">
                    <img src="assets/img/mbdlogo.png" alt="" style="    width: 8%;
                    " >
                    <h6 class="earnstake" >Your MBD staked</h6>
                </div>
                <div class="card-body">
                    <h3 class="text-white">{{playerdetails?.totalInvested/1e18 | number:'0.0-4' }}</h3>
                    <label  class="form-label">Referral Link :</label>
                    <div class="d-flex" >
                        <input
                    type="text"
                        value="{{baseUrl}}/ref/{{account}}"
                      class="form-control deposite-form"
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter Amount"
                      style="margin:0 10px;"
                      #refLink
                    />
                    <button type="submit" class="btn btn-primary btn-block depositebutton m-0" (click)="copyInputMessage(refLink)"><i class="fas fa-copy"></i> Copy </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header border1 d-flex ">
                    <img src="assets/img/mbdlogo.png" alt="" style="    width: 8%;
                    " >
                    <h6 class="earnstake" >Your MBD balance not farmed yet </h6>
                </div>
                <div class="card-body">
                    <h3 class="text-white">{{(available/1e18) | number:'0.0-4' }}</h3>
                    <button type="submit" (click)="withdraw()" class="btn btn-primary btn-block depositebutton"> <i class="fas fa-exchange-alt"></i> Withdraw </button>
                </div>
            </div>
        </div>
    </div>
  
    <div class="row justify-content-center margingap">
        <div class="col-md-2">
            <div class="card text-center ">
                <div class="card-header border1 ">
                    <h6 class="earnstake" >
                        LVL 1
                    </h6>
                </div>
                <div class="card-body">
                    <p class="rewards" >
                    35% Rewards

                    </p>
                    <p class="rewards1">
                        {{playerdetails?.referralEarningsL[0]/1e18}}
                    </p>
                </div>

            </div>
        </div>
        <div class="col-md-2">
            <div class="card text-center ">
                <div class="card-header border1 ">
                    <h6 class="earnstake" >
                        LVL 2
                    </h6>
                </div>
                <div class="card-body">
                    <p class="rewards" >
                        1% Rewards

                    </p>
                    <p class="rewards1">
                        {{playerdetails?.referralEarningsL[1]/1e18}}
                    </p>
                </div>

            </div>
        </div>
        <div class="col-md-2">
            <div class="card text-center ">
                <div class="card-header border1 ">
                    <h6 class="earnstake" >
                        LVL 3
                    </h6>
                </div>
                <div class="card-body">
                    <p class="rewards" >
                        2% Rewards
                    </p>
                    <p class="rewards1">
                        {{playerdetails?.referralEarningsL[2]/1e18}}
                    </p>
                </div>

            </div>
        </div>
        <div class="col-md-2">
            <div class="card text-center ">
                <div class="card-header border1 ">
                    <h6 class="earnstake" >
                        LVL 4
                    </h6>
                </div>
                <div class="card-body">
                    <p class="rewards" >
                        3% Rewards

                    </p>
                    <p class="rewards1">
                        {{playerdetails?.referralEarningsL[3]/1e18}}
                    </p>
                </div>

            </div>
        </div>
        <div class="col-md-2">
            <div class="card text-center ">
                <div class="card-header border1 ">
                    <h6 class="earnstake" >
                        LVL 5
                    </h6>
                </div>
                <div class="card-body">
                    <p class="rewards" >
                        4% Rewards

                    </p>
                    <p class="rewards1">
                        {{playerdetails?.referralEarningsL[4]/1e18}}
                    </p>
                </div>

            </div>
        </div>

        

    </div>


    <h2 class="section-title lasthead text-center my-5">
      <b class="base--color">GLOBAL </b>
      <span class="font-weight-normal text-white">POOL</span>
    </h2>
    <div class="row justify-content-center mb-5">
        <div class="col-md-4">
            <div class="card">
                
                <select class="form-select" (change)="changeRoundId($event)" aria-label="Default select example">
                    <option class="options" value="1" selected>Round 1</option>
                    <option class="options" value="2">Round 2</option>
                    <option class="options" value="3">Round 3</option>
                    <option class="options" value="4">Round 4</option>
                    <option class="options" value="5">Round 5</option>
                    <option class="options" value="6">Round 6</option>
                    <option class="options" value="7">Round 7</option>
                    <option class="options" value="8">Round 8</option>
                    <option class="options" value="9">Round 9</option>
                    <option class="options" value="10">Round 10</option>
                  </select>
            </div>
        </div>
    </div>
   
      <div class="table-responsive--md p-0 pb-5">
        <table class="table style--two white-space-nowrap">
          <thead>
            <tr>
              <th>Levels</th>
              <th>Balance</th>
              <th>Active Users</th>
              <th>Actions</th>
             
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="lavel">Level 1</td>
              <td data-label="Transaction ID">
                <span class="base--color"><p class=" m-3">
                  <span>1 </span>
                  <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" />
                </p></span>
              </td>
              <td data-label="Amount">
                <span class="text-success">{{totalUsersInMatrix}}
                </span>
              </td>
              <td data-label="Wallet">
                <span class="badge base--bg">
                              <button class="btn w-100 activebutton text-button">{{(matrixDetails?.activeLevel>=1 && matrixDetails?.planbactivatedround>=roundid*1) ? "Active" : "Inactive"}}</button>

</span>
              </td>
              
            </tr>
            <tr>
              <td data-label="lavel">Level 2</td>
              <td data-label="Transaction ID">
                <span class="base--color"><p class=" m-3">
                  <span>2 </span>
                  <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" />
                </p></span>
              </td>
              <td data-label="Amount">
                <span class="text-success">{{totalUsersInMatrix}}
                </span>
              </td>
              <td data-label="Wallet">
                <span class="badge base--bg">              <button class="btn w-100 activebutton text-button">{{matrixDetails?.activeLevel>=2 ? "Active" : "Inactive"}}</button>
</span>
              </td>
              
            </tr>
           
            
          </tbody>
        </table>

      </div>

    
  </div>
</section>
