<section style="background-image: linear-gradient(#000000bf, rgb(3 3 3 / 66%))">
  <app-header></app-header>
  <div class="container">
    
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="card jackpotfund">
          <div class="row">
            <div class="col-md-8 m-auto">
              <h5 class="jackpotheading">TOTAL EARNING</h5>
              <p class="m-0">
                <span>37465734258</span>
                <img
                  src="assets/img/icon.webp"
                  alt=""
                  width="30px"
                  style="margin: 0 10px"
                  class="moneyicon"
                />
              </p>
            </div>
            <div class="col-md-4 text-end ">
              <i class="fa-solid fa-heart dashboardicon "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card jackpotfund">
          <div class="row">
            <div class="col-md-8">
              <h5 class="jackpotheading">REFFERAL EARNING</h5>
              <p class="m-0">
                <span>37465734258</span>
                <img
                  src="assets/img/icon.webp"
                  alt=""
                  width="30px"
                  style="margin: 10px"
                  class="moneyicon"
                />
              </p>
            </div>
            <div class="col-md-4 text-end">
              <i class="fa-solid fa-cannabis dashboardicon "></i>
            </div>
          </div>
         
         
        </div>
      </div>

      <div class="col-md-4">
        <div class="card jackpotfund">
          <div class="row">
            <div class="col-md-8">
              <h5 class="jackpotheading">GLOBAL POOL INCOME</h5>
              <p class="m-0">
                <span>37465734258</span>
                <img
                  src="assets/img/icon.webp"
                  alt=""
                  width="30px"
                  style="margin: 10px"
                  class="moneyicon"
                />
              </p>
            </div>
            <div class="col-md-4 text-end">
              <i class="fa-solid fa-money-bills dashboardicon "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card jackpotfund">
          <div class="row">
            <div class="col-md-8">
              <h5 class="jackpotheading">REFFERED PARTNER'S</h5>
              <p class="m-0">
                <span>37465734258</span>
                <img
                  src="assets/img/user.png"
                  alt=""
                  width="30px"
                  style="margin: 10px" class="moneyicon"
                />
              </p>
            </div>
            <div class="col-md-4 text-end">
              <i class="fa-solid fa-handshake dashboardicon "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card jackpotfund">
          <div class="row">
            <div class="col-md-8">
              <h5 class="jackpotheading">TOTAL PARTICIPANTS</h5>
              <p class="m-0">
                <span>37465734258</span>
                <img
                  src="assets/img/user.png"
                  alt=""
                  class=""
                  width="30px"
                  style="margin: 10px"
                  class="moneyicon"
                />
              </p>
            </div>
            <div class="col-md-4 text-end">
              <i class="fa-solid fa-person-military-to-person dashboardicon "></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
       
          <div class="card">
            <h1 style="font-size: 30px; padding: 0 0 10px">Current Round</h1>
            <select class="form-select" aria-label="Default select example">
              <option class="options" selected>Round 1</option>
              <option class="options" value="1">Round 2</option>
              <option class="options" value="2">Round 3</option>
              <option class="options" value="3">Round 4</option>
            </select>
          </div>
        
      </div>
    </div>

    <div class="row justify-content-center ">
     
    </div>
    <h2 class="section-title lasthead text-center my-5">
      <b class="base--color">GLOBAL </b>
      <span class="font-weight-normal text-white">POOL</span>
    </h2>

    <div class="table-responsive--md p-0 pb-5">
      <table class="table style--two white-space-nowrap">
        <thead>
          <tr>
            <th>Levels</th>
            <th>Balance</th>
            <th>Active Users</th>
            <th>Actions</th>
           
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label="lavel">Level 1</td>
            <td data-label="Transaction ID">
              <span class="base--color"><p class=" m-3">
                <span>37465734258</span>
                <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" />
              </p></span>
            </td>
            <td data-label="Amount">
              <span class="text-success">143
              </span>
            </td>
            <td data-label="Wallet">
              <span class="badge base--bg"><button class="btn w-100 activebutton">Active</button></span>
            </td>
            
          </tr>
          <tr>
            <td data-label="lavel">Level 1</td>
            <td data-label="Transaction ID">
              <span class="base--color"><p class=" m-3">
                <span>37465734258</span>
                <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" />
              </p></span>
            </td>
            <td data-label="Amount">
              <span class="text-success">143
              </span>
            </td>
            <td data-label="Wallet">
              <span class="badge base--bg"><button class="btn w-100 activebutton">Active</button></span>
            </td>
            
          </tr>
          <tr>
            <td data-label="lavel">Level 1</td>
            <td data-label="Transaction ID">
              <span class="base--color"><p class=" m-3">
                <span>37465734258</span> 
                <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" />
              </p></span>
            </td>
            <td data-label="Amount">
              <span class="text-success">143
              </span>
            </td>
            <td data-label="Wallet">
              <span class="badge base--bg"><button class="btn w-100 activebutton">Active</button></span>
            </td>
            
          </tr>
          <tr>
            <td data-label="lavel">Level 1</td>
            <td data-label="Transaction ID">
              <span class="base--color"><p class=" m-3">
                <span>37465734258</span>
                <img src="assets/img/icon.webp" alt="" width="30px" class="moneyicon" />
              </p></span>
            </td>
            <td data-label="Amount">
              <span class="text-success">143
              </span>
            </td>
            <td data-label="Wallet">
              <span class="badge base--bg"><button class="btn w-100 activebutton">Active</button></span>
            </td>
            
          </tr>
          
        </tbody>
      </table>
    </div>
   
  </div>
</section>
