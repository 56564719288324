import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/services/global.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-connect-wallet',
  templateUrl: './connect-wallet.component.html',
  styleUrls: ['./connect-wallet.component.scss']
})
export class ConnectWalletComponent implements OnInit {
  modalOpened: boolean = false;

  constructor(public dialogRef: MatDialogRef<ConnectWalletComponent>,private cs:GlobalService) { }

  ngOnInit(): void {
    this.getDetails();
  }

   getDetails()
  {
     this.cs.getWalletObs().subscribe((data:any)=>{
      if(data.address!=undefined && this.modalOpened){
        this.closeDialog();
      }
      
    });
  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }

  connect(walletType:any)
  {
    this.modalOpened=true;
    if(walletType==1){
    this.cs.connectContract();
    }
    else
    {
      this.cs.connectAccountWalletConnect();
    }
  }
}
