import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ROIComponent } from './dashboard/roi/roi.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path:'header', component:HeaderComponent
  },
 {
  path:'home', component:HomeComponent
 },
  {
    path:'footer', component:FooterComponent
  },
  {
    path:'dashboard', component:DashboardComponent
  },
  {
    path:'ROI', component:ROIComponent
  },
  {path : 'ref/:id',component:ROIComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
