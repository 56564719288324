<section class="banner-image">
  <app-header></app-header>

  <div>
    <img src="assets/img/left 1.png" alt="" class="left" />
    <img src="assets/img/right 1.png" alt="" class="right" />
  </div>

  <div class="container">
    <div class="row" style="padding: 13rem 0">
      <div class="col-md-12" style="position: relative; text-align: center; " >
        
        <div >
          <h1 class="headrhead">
            <img
          src="assets/img/remove.png"
          class="kingcrown"
          alt=""
          style="
    width: 13%;
    margin: auto;
    position: absolute;
    top: -74px;
    left: 9%;
    transform: rotate3d(1, 1, 1, 322deg);
    z-index: 0;
"
        />
            Smart 
            <span class="text-white">Contract</span>
          </h1>
          <p class="fasthead">
            The Fasted, Easiest and risk-free way to earn 500000-20m+TRX
          </p>

          <a href=""
            ><button class="btn loginbuttons mt-5">Login/Register</button></a
          >
        </div>
        <div class="row py-5 justify-content-center">
          <div class="col-md-8">
            <div class="referralcopy">
              <div class="d-flex " style="justify-content: space-between;" >
                <h5 class="m-0 referral_register">Referral Link :</h5>
                <p class="m-0">
                  http://codetentacles-006-site9.htempurl.com/referral_register/1
                </p>
                <button class="btn p-0"><i class="fa-solid fa-copy" style="color: #cca354;" ></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</section>

<div style="background-image: linear-gradient(#000000bf, rgb(3 3 3 / 66%))" >
  <section style="padding: 50px 0 0;" >
    <div class="container">
      <div class="row mb-none-30">
        <div class="col-lg-3 col-sm-6 cureency-item mb-30">
          <div class="cureency-card text-center">
            <h6 class="cureency-card__title text-white">$9250-2250000</h6>
            <span
              class="cureency-card__amount h-font-family font-weight-600 base--color"
              >(Receive at every Circle)</span
            >
          </div>
          <!-- cureency-card end -->
        </div>
        <!-- cureency-item end -->
        <div class="col-lg-3 col-sm-6 cureency-item mb-30">
          <div class="cureency-card text-center">
            <h6 class="cureency-card__title text-white">Guarantees</h6>
            <span
              class="cureency-card__amount h-font-family font-weight-600 base--color"
              >Impossible to lose Money</span
            >
          </div>
          <!-- cureency-card end -->
        </div>
        <!-- cureency-item end -->
        <div class="col-lg-3 col-sm-6 cureency-item mb-30">
          <div class="cureency-card text-center">
            <h6 class="cureency-card__title text-white">500 TRX</h6>
            <span
              class="cureency-card__amount h-font-family font-weight-600 base--color"
              >Activate Your Contract</span
            >
          </div>
          <!-- cureency-card end -->
        </div>
        <!-- cureency-item end -->
        <div class="col-lg-3 col-sm-6  mb-30">
          <div class="cureency-card text-center">
            <h6 class="cureency-card__title text-white">Mini Pack</h6>
            <span
              class="cureency-card__amount h-font-family font-weight-600 base--color"
            ></span>
          </div>
          <!-- cureency-card end -->
        </div>
        <!-- cureency-item end -->
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <h2 class="section-title text-center my-5">
        <span class="font-weight-normal text-white">Why Choose</span>
        <b class="base--color"> Hyiplab</b>
      </h2>
      <div class="row">
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-times-circle"></i>
              </div>
              <h4 class="choose-card__title base--color">Absolutely no risk</h4>
            </div>
            <p class="cardpara">
              It’s an Open source smart contract uploaded on TRON Blockchain
              Protocol, and this is totally decentralized.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-users"></i>
              </div>
              <h4 class="choose-card__title base--color">
                Available to everyone
              </h4>
            </div>
            <p class="cardpara">With a Single Contract Entry Cost – 500 TRX</p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-heartbeat"></i>
              </div>
              <h4 class="choose-card__title base--color">Stable Long Term</h4>
            </div>
            <p class="cardpara">
              This Smart Contract will continue until blockchain no longer exists,
              and even the creators of a smart contract will not be able to delete
              or change its terms.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-comment-dollar"></i>
              </div>
              <h4 class="choose-card__title base--color">
                Instant payouts, No Admin Approvals
              </h4>
            </div>
            <p class="cardpara">
              Money is not stored in the system, all transactions are 100% from
              wallets to participant's wallets. Wallets are anonymous.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-skiing"></i>
              </div>
              <h4 class="choose-card__title base--color">Kept simple and fast</h4>
            </div>
            <p class="cardpara">
              Binary matrix 5 lines - 62 partners in the team are enough to go
              through cycles and earn over 500000 TRX.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-headset"></i>
              </div>
              <h4 class="choose-card__title base--color">LIVE Tech Support</h4>
            </div>
            <p class="cardpara">
              Active members & uplines will help you take steps to start earning
              regularly. x3mTRON gives you live support.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="lab la-hackerrank"></i>
              </div>
              <h4 class="choose-card__title base--color">
                The system cannot be hacked
              </h4>
            </div>
            <p class="cardpara">
              It is not possible to change the algorithm or delete the members'
              rooms. The system is able to work without a site. The site is needed
              only for convenient display of statistics.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="lar la-copy"></i>
              </div>
              <h4 class="choose-card__title base--color">
                The Uniqueness of x3mTRON
              </h4>
            </div>
            <p class="cardpara">
              These Features, make x3mTRON the best available of it's kind.
            </p>
          </div>
        </div>
        <div class="col-md-4 cards">
          <div class="choose-card border-radius--5">
            <div class="choose-card__header mb-3">
              <div class="choose-card__icon">
                <i class="las la-wallet"></i>
              </div>
              <h4 class="choose-card__title base--color">Passive Income</h4>
            </div>
            <p class="cardpara">
              With our unique auto-fill system, you can repeat circle earnings
              within a short period, by simply pressing the “repeat” button. Earn
              from 500000-2m+ TRX at every circle.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <h2 class="section-title text-center my-5">
        <span class="font-weight-normal text-white">Advanced </span>
        <b class="base--color"> Technology</b>
      </h2>
      <div class="row">
        <div class="col-md-3">
          <div class="choose-card1 border-radius--5">
            <div class="choose-card__header1 mb-3">
              <div class="choose-card__icon1">
                <i class="fa-solid fa-tent-arrow-left-right"></i>
              </div>
              <h4 class="choose-card__title1 base--color">Total Transactions</h4>
            </div>
            <p class="cardpara1">8101</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="choose-card1 border-radius--5">
            <div class="choose-card__header1 mb-3">
              <div class="choose-card__icon1">
                <i class="fa-regular fa-address-card"></i>
              </div>
              <h4 class="choose-card__title1 base--color">
                24 Hours Registration
              </h4>
            </div>
            <p class="cardpara1">+0</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="choose-card1 border-radius--5">
            <div class="choose-card__header1 mb-3">
              <div class="choose-card__icon1">
                <i class="fa-solid fa-handshake"></i>
              </div>
              <h4 class="choose-card__title1 base--color">Total Participants</h4>
            </div>
            <p class="cardpara1">4154</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="choose-card1 border-radius--5">
            <div class="choose-card__header1 mb-3">
              <div class="choose-card__icon1">
                <i class="fa-solid fa-arrow-trend-down"></i>
              </div>
              <h4 class="choose-card__title1 base--color">Members Earned TRX</h4>
            </div>
            <p class="cardpara1">41540000</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section>
    <div class="container">
      <h2 class="section-title text-center my-5">
        <span class="font-weight-normal text-white">Frequently Asked </span>
        <b class="base--color"> Questions</b>
      </h2>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Lorem ipsum dolor sit amet?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                nesciunt porro placeat doloremque deserunt rerum in! Pariatur
                repellendus rem explicabo! Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Non nesciunt porro placeat doloremque deserunt
                rerum in! Pariatur repellendus rem explicabo! Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Non nesciunt porro placeat
                doloremque deserunt rerum in! Pariatur repellendus rem explicabo!
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                nesciunt porro placeat doloremque deserunt rerum in! Pariatur
                repellendus rem explicabo!
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis odit
              ea officia esse vel sed?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                nesciunt porro placeat doloremque deserunt rerum in! Pariatur
                repellendus rem explicabo!
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading3">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse3"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque
              quasi culpa perspiciatis ducimus odit officiis?
            </button>
          </h2>
          <div
            id="collapse3"
            class="accordion-collapse collapse"
            aria-labelledby="heading3"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Non
                nesciunt porro placeat doloremque deserunt rerum in! Pariatur
                repellendus rem explicabo!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <h2 class="section-title lasthead text-center my-5">
        <b class="base--color"> What </b>
        <span class="font-weight-normal text-white">our users have to say </span>
      </h2>
  
      <div class="row" style="position: relative">
        <div class="col-md-3">
          <div class="box box-down cyan box-1">
            <p>
              "Just super :) We have a better feeling now when our daughter is on
              her way home alone...accurate to within a few meters. Great app
              therefore also 5 stars"
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="box box-down cyan box-2">
            <p>
              "Just super :) We have a better feeling now when our daughter is on
              her way home alone...accurate to within a few meters. Great app
              therefore also 5 stars"
            </p>
          </div>
        </div>
  
        <div class="col-md-3">
          <div class="box box-down cyan box-3">
            <p>
              "Very very very useful especially because the person who wants to be
              tracked starts the way by himself and can not be observed without
              him knowing this. Super app for our daughter,."
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="box box-down cyan box-4">
            <p>
              "Very very very useful especially because the person who wants to be
              tracked starts the way by himself and can not be observed without
              him knowing this. Super app for our daughter,."
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
