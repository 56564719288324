import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/services/global.service';
import { ConnectWalletComponent } from '../connect-wallet/connect-wallet.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  account: any;
  isConnected: boolean = false;
  playerdetails: any;
  constructor(private cs: GlobalService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getaccount();
  }

  async getaccount() {
    try {
      this.cs.init();

      this.cs.getWalletObs().subscribe((data: any) => {
        if (this.cs.isValidAddress(data)) {
          this.account = data;
          this.isConnected = true;
          let that = this;
        } else {
          this.isConnected = false;
        }
      });
    } catch (e) {
      this.isConnected = false;
    }
  }

  async connect() {
    // if(walletType==1)
    // this.cs.connectToWallet();
    // else
    // this.cs.connectToWalletConnect();
    let dialogRef = this.dialog.open(ConnectWalletComponent, {
      height: 'auto',
      width: '340px',
      panelClass: 'custom-modalbox',
    });
    // this.cs.connectContract();
  }


}
