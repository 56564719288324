<section>
    <nav class="navbar navbar-expand-lg" style="background-color: rgb(11 29 75 / 0%)" >
        <div class="container">
          <a class="navbar-brand logo " routerLink="/home"  >
            <img src="assets/img/mbdlogo.png" alt="" style="width: 11%;" >
          </a>
          <button class="navbar-toggler" style="background-color: white;" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end " id="navbarSupportedContent">
           
            <div class="d-flex">
              <a *ngIf="!isConnected" (click)="connect()" class="sc-fzoXzr fUiryO connectwallet">
                <i class="fa-brands fa-google-wallet"></i> My Wallet
              </a>
              <a *ngIf="isConnected" class="sc-fzoXzr fUiryO connectwallet">
                <i class="fa-solid fa-location-arrow"></i>
                {{ account.substring(0, 4) }}...{{ account.substring(38, 42) }}
              </a>

              <!-- <a [routerLink]="['/dashboard']"><button class="btn  dashboard" > Dashboard </button></a> -->
              <a [routerLink]="['/ROI']"><button class="btn ROI" > ROI </button></a>
           

             
            </div>
          </div>
        </div>
      </nav>
</section>