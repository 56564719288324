import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { ConnectWalletComponent } from 'src/app/connect-wallet/connect-wallet.component';
import { GlobalService } from 'src/services/global.service';

@Component({
  selector: 'app-roi',
  templateUrl: './roi.component.html',
  styleUrls: ['./roi.component.scss']
})
export class ROIComponent implements OnInit {

  hidestar: boolean = false;
  contractaddress: any;
  url_id: string = '';
  account: any;
  isConnected: boolean = false;
  playerdetails: any;
  availableToWithdraw: any;
  userbalance: any;
  contractInfo: any;
  isBusd: any = true;
  base_url = location.origin;
  currency = 'BNB';
  value: number | undefined;
  available: any;
  baseUrl = location.origin;
  eagleFunds: any;
  royaltyFunds: any;
  pensionFunds: any;
  matrixDetails: any;
  totalUsersInMatrix: any;
  roundid: any=1;
  commonPlayerDetails: any;

  constructor(
    private cs: GlobalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    localStorage.setItem('type', "2");

    setTimeout(() => {
      this.hidestar = false;
    }, 5000);
    this.getaccount();
    let that: any = this;
    window.addEventListener(
      'message',
      function (e: any) {
        if (e.data.message && e.data.message.action == 'setAccount') {
          if (that.account != e.data.message.data.address) {
            that.account = e.data.message.data.address;
            that.getaccount();
            that.emptyamount = false;
            that.erroroccured = false;
            that.erroroccuredaccount = false;
          }
        }
      }.bind(this)
    );
    this.isBusd = localStorage.getItem('type') ?? '1';

    if (this.isBusd == "2") {
      this.currency = 'BUSD';
      this.value = 10;
    } else {
      this.currency = 'BNB';
      this.value = 0.05;
    }

    this.url_id = this.cs.getUplineid(this.route);
  }

  async getaccount() {
    try {
      this.cs.init();

      this.cs.getWalletObs().subscribe((data: any) => {
        if (this.cs.isValidAddress(data)) {
          this.account = data;
          this.isConnected = true;
          let that = this;
          setInterval(function () {
            that.loadData();
          }, 5000);

          this.loadData();
          this.getMatrixData(1);
        } else {
          this.isConnected = false;
        }
      });
    } catch (e) {
      this.isConnected = false;
    }
  }

  
  async loadData() {
    this.isConnected = true;
    // this.commonPlayerDetails = await this.cs.commonContract._userInfo(this.account);
     this.playerdetails = await this.cs.UserInfo();

    console.log('********************************');
    console.log(parseInt(this.playerdetails.totalInvested, 16));
    
     this.contractInfo = await this.cs.contractInfo();
     this.available = await this.cs.stakingContract.getAvailabel(this.account);
    this.cs
      .getBalanceByAddress(this.account)
      .then((balance: any) => (this.userbalance = balance));

      this.eagleFunds = await this.cs.stakingContract.EgaleFund();
      this.royaltyFunds = await this.cs.stakingContract.royaltyFunds();
      this.pensionFunds = await this.cs.stakingContract.pensionFunds();
      
  }

  async getMatrixData(roundId:any)
  {
    this.matrixDetails = await this.cs.stakingContract.matrixUsers(this.account);
    this.totalUsersInMatrix = await this.cs.stakingContract.lastIDCount(roundId);
  }

  changeRoundId(roundId:any)
  {
    this.roundid = roundId.target.value;
    this.getMatrixData(roundId.target.value);
  }

  async deposit(amount: any) {
    try {
      let amountInEth = ethers.utils.parseEther(amount);
      if (localStorage.getItem('type') == '2') {
        let allowance: any = await this.cs.checkAllowance(
          this.account,
          amountInEth
        );
          debugger;
        if (allowance.status && allowance.allowance) {
          await this.cs.deposit(this.url_id, amountInEth);
        } else if (allowance.status && !allowance.allowance) {
          let allowToken: any = await this.cs.approveToken(amountInEth);
          if (allowToken.status) {
            await allowToken.hash.wait(3);
            await this.cs.deposit(this.url_id, amountInEth);
          }
        }
      } else {
        await this.cs.deposit(this.url_id, amountInEth);
      }
    } catch (e : any) {
      debugger;
      this.toastr.error(e.data.message);
    }
  }

  async withdraw() {
    try {
      await this.cs.withdraw();
    } catch (e:any) {
      this.toastr.error(e.data.message);
    }
  }

  async setContractType(type: number) {
    localStorage.setItem('type', "2");
    location.reload();
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('Copied!');
  }
}
